




























































































import Vue from "vue";

import TextField from "@/components/formbuilder/TextField.vue";
import TextAreaField from "@/components/formbuilder/TextAreaField.vue";
import SelectField from "@/components/formbuilder/SelectField.vue";
import FormFieldList from "@/components/formbuilder/FormFieldList.vue";
import FormFieldSettings from "@/components/formbuilder/FormFieldSettings.vue";
import { Form, FormField, Role } from "@/types";
import FormBackgroundImage from "@/components/formbuilder/FormBackgroundImage.vue";
import formBuilderStoreModule from "@/store/modules/form";
import { createNamespacedHelpers } from "vuex";

const { mapActions: formActions } = createNamespacedHelpers("FORM_EDITOR");

export default Vue.extend({
  name: "FormBuilder",
  props: {
    formId: {
      type: String,
      required: true,
    },
  },
  components: {
    TextField,
    TextAreaField,
    SelectField,
    FormFieldList,
    FormFieldSettings,
    FormBackgroundImage,
  },
  data: () => ({
    tab: 0,
    name: "",
    fields: [] as FormField[],
    fieldIndex: -1,
    showUploadBgDialog: false,
    image: undefined as undefined | File,
    formNameRules: [(v: string) => !!v || "Form name is required"],
  }),
  created() {
    this.fetchForm(`?formId=${this.formId}`).then((form) => {
      if (form) {
        this.name = form.name;
        this.fields = form.formFields;
        // this.image = form.image
      }
    });
  },
  computed: {
    role(): Role {
      return this.$store.getters.role;
    },
    form(): Form {
      return this.$store.getters["FORM_EDITOR/getForm"](this.formId);
    },
  },
  methods: {
    ...formActions(["createForm", "updateForm", "fetchForm"]),
    addField(field: FormField) {
      this.fields.push({
        ...field,
        ...{
          settings: {
            label: "",
            showPlaceholder: false,
            placeholder: "",
            isRequired: false,
            showHint: false,
            hint: "",
          },
        },
      });
      if (field.options) {
        this.fields[this.fields.length - 1].options = field.options;
      }
      this.fieldIndex = this.fields.length - 1;
      this.tab = 1;
    },
    setFieldIndex(index: number) {
      this.fieldIndex = index;
      this.tab = 1;
    },
    deleteField(index: number) {
      this.fields.splice(index, 1);
      this.fieldIndex = -1;
    },
    displayImage(image: File) {
      this.showUploadBgDialog = false;
      this.image = image;
      const reader = new FileReader();
      reader.onloadend = () => {
        const fields = document.getElementById("form-fields") as HTMLElement;

        fields.style.backgroundImage = `url(${reader.result})`;
      };

      reader.readAsDataURL(this.image);
    },
    removeOption(index: number) {
      this.fields[this.fieldIndex].options.splice(index, 1);
    },
    addOption() {
      this.fields[this.fieldIndex].options.push("Option");
    },
    saveForm() {
      // const data = new FormData();
      // if (this.image) {
      //   data.append("file", this.image);
      // }
      this.updateForm({
        id: this.form._id,
        form: {
          name: this.name,
          formFields: this.fields.map((item) => ({
            name: item.name,
            type: item.type,
            settings: {
              label: item.settings.label,
              showPlaceholder: item.settings.showPlaceholder,
              placeholder: item.settings.placeholder,
              isRequired: item.settings.isRequired,
              showHint: item.settings.showHint,
              hint: item.settings.hint,
            },
          })),
        },
      });
      this.$router.push(`/forms/${this.form._id}`);
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("FORM_EDITOR")) {
      this.$store.registerModule("FORM_EDITOR", formBuilderStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("FORM_EDITOR");
  },
});
