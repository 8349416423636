































import Vue from "vue";
import { VueConstructor } from "vue/types/umd";

export default (
  Vue as VueConstructor<
    Vue & {
      $refs: {
        imageField: HTMLInputElement;
      };
    }
  >
).extend<any, any, any, any>({
  name: "FormBackgroundImage",
  props: {
    title: {
      type: String,
    },
  },
  data: () => ({
    rules: [
      (value: File) => !!value || "Image field is required",
      (value: File) =>
        !value || value.size / 1e6 < 3 || "Maximum allowed Image size is 3MB!",
    ],
    image: undefined as undefined | File,
  }),
  mounted() {
    this.image = undefined;
    (
      this.$refs.uploadForm as Element & {
        resetValidation: () => void;
      }
    )?.resetValidation();
  },
  methods: {
    handleUpload(file: File) {
      this.image = file;
    },
    upload() {
      const valid = (
        this.$refs.uploadForm as Element & {
          validate: () => boolean;
        }
      )?.validate();
      if (!valid) return;

      this.$emit("data-valid", this.image);
    },
  },
});
