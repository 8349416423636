




















































import Vue, { PropType } from "vue";

import { FormFieldSetting } from "@/types";

export default Vue.extend<any, any, any, any>({
  name: "FormFieldSetting",
  props: {
    fieldSettings: {
      type: Object as PropType<FormFieldSetting>,
      required: true,
    },
  },
  methods: {
    onInputHandler(setting: string, value: string | boolean) {
      const settings: { [key: string]: string | boolean } = {};
      settings[setting] = value;
      // console.log("input: ", { ...this.fieldSettings, ...settings });
      this.$emit("input", { ...this.fieldSettings, ...settings });
    },
  },
});
