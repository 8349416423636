




























import Vue, { PropType } from "vue";

import { FormField } from "@/types";

export default Vue.extend<any, any, any, any>({
  name: "SelecttField",
  props: {
    field: {
      type: Object as PropType<FormField>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    fieldEdittable: {
      type: Boolean,
    },
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rules(): any[] {
      if (this.field.settings.isRequired)
        return [
          (v: string) =>
            !!v || `${this.field.settings.label} field is required`,
        ];
      return [];
    },
  },
  methods: {
    //
  },
});
